<template>
  <v-container class="pa-0">
    <side-content
      modal
      hide-submit
      :title="title"
      :open="openModal"
      :is-subcontext="isSubcontext"
      @back="sideContentBack"
      @close="closeModal">
      <template>
        <v-container grid-list-lg>
          <v-form ref="form" v-model="valid">
            <v-jsf v-model="model" :schema="schema" :options="formOptions">
              <template v-slot:flows-proposal-has="slotProps">
                <v-switch v-model="model.flows.proposal.has" :label="slotProps.label" :rules="flowsHasValidation" append-icon="edit" @click:append="editFlowProposal" />
              </template>
              <template v-slot:flows-negotiation-has="slotProps">
                <v-switch v-model="model.flows.negotiation.has" :label="slotProps.label" :rules="flowsHasValidation" append-icon="edit" @click:append="editFlowProposal" />
              </template>
              <template v-slot:flows-mediators-has="slotProps">
                <v-switch v-model="model.flows.mediators.has" :label="slotProps.label" :rules="flowsHasValidation" append-icon="edit" @click:append="editFlowProposal" />
              </template>

              <template slot="calculateParams">
                <p>As Informações para Cálculo servem para a atualizar monetariamente os créditos inadimplidos, bem como aplicar a multa, taxas de juros e demais acréscimos previstos em lei, contrato ou sentença judicial.</p>

                <v-list v-if="model.calculateParams.length > 0" class="pa-0" dense>
                  <template v-for="(item, index) in model.calculateParams">
                    <v-list-item :key="index" class="px-2">
                      <v-list-item-content>
                        <v-list-item-title>
                          {{ item.year }}
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              class="mx-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense flat class="pa-0">
                            <v-list-item class="px-2" @click="editInstallmentRule(item)">
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="index + 1 < model.calculateParams.length" :key="'divider-' + index" />
                  </template>
                </v-list>

                <div class="d-flex">
                  <v-spacer />
                  <v-btn icon color="primary" @click="addCalculateParams">
                    <v-icon>add</v-icon>
                  </v-btn>
                </div>
              </template>

              <template slot="flows-proposal-installmentRule">
                <h3 class="d-flex">
                  <span>Regras de parcelamento</span>
                  <v-spacer />
                  <v-btn icon color="primary" @click="addInstallmentRule">
                    <v-icon>add</v-icon>
                  </v-btn>
                </h3>

                <v-list v-if="model.flows.proposal.installmentRule.length > 0" class="pa-0" dense>
                  <template v-for="(item, index) in model.flows.proposal.installmentRule">
                    <v-list-item :key="index" class="px-2">
                      <v-list-item-content>
                        <v-list-item-title v-if="item.parcelOf === 1 && item.parcelUntil === 1">
                          À vista
                        </v-list-item-title>
                        <v-list-item-title v-else>
                          De {{ item.parcelOf }} a {{ item.parcelUntil }} parcelas
                        </v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-menu offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              icon
                              small
                              class="mx-0"
                              v-bind="attrs"
                              v-on="on">
                              <v-icon>more_vert</v-icon>
                            </v-btn>
                          </template>
                          <v-list dense flat class="pa-0">
                            <v-list-item class="px-2" @click="editInstallmentRule(item)">
                              <v-list-item-title>Editar</v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </v-list-item-action>
                    </v-list-item>

                    <v-divider v-if="index + 1 < model.flows.proposal.installmentRule.length" :key="'divider-' + index" />
                  </template>
                </v-list>
              </template>

              <!-- <template slot="flows-negotiation-has-append">append</template> -->
              <!-- <template v-slot:flows-negotiation-has-append>append</template> -->
              <!-- <template v-slot:flows-negotiation-has-append="slotProps">before (slot props={{ console.log(slotProps)}}).</template> -->
              <!-- <template v-slot:teste-before="slotProps">before (slot props={{Object.keys(slotProps)}}).</template> -->
              <!-- <template v-slot:teste="{value, on}">
                <p class="mt-4">
                  this is the default slot of the 2nd property
                  <input type="text" :value="value" v-on="on" style="border:1px solid red;">.
                </p>
              </template> -->
            </v-jsf>
          </v-form>
        </v-container>
      </template>
      <template v-slot:footer>
        <v-btn v-if="flowActive" color="primary" :disabled="!valid" block @click="sideContentBack">
          Salvar
        </v-btn>
        <v-btn v-else color="primary" block @click="crateCampaign(model)">
          Adicionar Campanha
        </v-btn>
      </template>
    </side-content>
    <v-dialog
      v-model="modalCalculateParams"
      content-class="modalCentral"
      persistent
      overlay-opacity="0.8"
      max-width="600px">
      <v-card>
        <v-card-title>
          <h3>Parâmetros de Calculo</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="newCalculateParams.correctionIndex"
                  :items="correctionIndexes"
                  item-text="title"
                  item-value="const"
                  label="Correção monetária"
                  filled
                  required />
              </v-col>
            </v-row>
            <h3>Parâmetros de cálculo nas parcelas selecionadas</h3>
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch v-model="newCalculateParams.fineProRata" label="Multa ProRata" hint="Multa cobrada...." persistent-hint />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch v-model="newCalculateParams.interestRateFixed" label="Juros Fixos" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-switch v-model="newCalculateParams.interestedFullMonth" label="Juros por mês" />
              </v-col>
            </v-row>
            <h3>Parâmetros de cálculo nas parcelas selecionadas</h3>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Multa moratória"
                  class="py-0"
                  :set-value="newCalculateParams.fine"
                  :max=100
                  is-relative-max
                  fixed-type
                  @change="calculateFine" />
              </v-col>
              <v-col v-if="newCalculateParams.fineProRata" cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Multa Máxima"
                  class="py-0"
                  :set-value="newCalculateParams.fineMaxPercent"
                  :max=100
                  is-relative-max
                  fixed-type
                  @change="calculateFine" />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Juros moratórios"
                  class="py-0"
                  :set-value="newCalculateParams.interestRate * 100"
                  :max=100
                  is-relative-max
                  fixed-type
                  @change="calculateInterestRate" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-number-field
                  label="Juros de parcelamento"
                  class="py-0"
                  :set-value="newCalculateParams.rateInstallments * 100"
                  :max=100
                  is-relative-max
                  fixed-type
                  @change="calculateRateInstallments" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-number-field
                  label="Honorários advocatícios"
                  class="py-0"
                  :set-value="newCalculateParams.fees * 100"
                  :max=100
                  is-relative-max
                  fixed-type
                  @change="calculateFees" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6" class="d-flex pr-0">
              <v-spacer />
              <v-btn class="mr-4 px-6" outlined @click="saveCalculateParams(newCalculateParams)">
                Confirmar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex pl-0">
              <v-btn class="ml-2 px-6" dark @click="closeCalculateParams">
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="modalAddInstallment"
      content-class="modalCentral"
      persistent
      overlay-opacity="0.8"
      max-width="600px">
      <v-card>
        <v-card-title>
          <h3>{{ installmentRuleTitle }}</h3>
        </v-card-title>
        <v-card-text>
          <v-container class="pa-0">
            <v-row>
              <v-col v-if="newRule.parcelOf > 1" cols="12" sm="6" class="pb-0">
                <v-text-field
                  v-model.number="newRule.parcelUntil"
                  label="Número de parcelas"
                  :prefix="`De ${newRule.parcelOf} a`"
                  filled
                  required />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-select
                  v-model="newRule.calculateParams.correctionIndex"
                  :items="correctionIndexes"
                  item-text="title"
                  item-value="const"
                  label="Correção monetária"
                  filled
                  required />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Desconto na correção monetária"
                  class="py-0"
                  fixed-type
                  @change="calculateCorrectionIndex" />
              </v-col>
            </v-row>
            <h3>Parâmetros de cálculo nas parcelas selecionadas</h3>
            <v-row>
              <v-col cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Multa moratória"
                  class="py-0"
                  :set-value="model.calculateParams.fine * 100"
                  :max="model.calculateParams.fine"
                  :disabled="model.calculateParams.fine === 0"
                  is-relative-max
                  fixed-type
                  @change="calculateFine" />
              </v-col>
              <v-col cols="12" sm="6" class="pb-0">
                <v-number-field
                  label="Juros moratórios"
                  class="py-0"
                  :set-value="model.calculateParams.interestRate * 100"
                  :max="model.calculateParams.interestRate"
                  :disabled="model.calculateParams.interestRate === 0"
                  is-relative-max
                  fixed-type
                  @change="calculateInterestRate" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-number-field
                  label="Juros de parcelamento"
                  class="py-0"
                  :set-value="model.calculateParams.rateInstallments * 100"
                  :max="model.calculateParams.rateInstallments"
                  :disabled="model.calculateParams.rateInstallments === 0"
                  is-relative-max
                  fixed-type
                  @change="calculateRateInstallments" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-number-field
                  label="Honorários advocatícios"
                  class="py-0"
                  :set-value="model.calculateParams.fees * 100"
                  :max="model.calculateParams.fees"
                  :disabled="model.calculateParams.fees === 0"
                  is-relative-max
                  fixed-type
                  @change="calculateFees" />
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-number-field
                  label="Desconto no valor original"
                  class="py-0"
                  fixed-type
                  @change="calculateDiscount" />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12" sm="6" class="d-flex pr-0">
              <v-spacer />
              <v-btn class="mr-4 px-6" outlined @click="saveInstallmentRule(newRule)">
                Confirmar
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex pl-0">
              <v-btn class="ml-2 px-6" dark @click="closeInstallmentRule">
                Cancelar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  const SidebarContent = () => import('@/components/ui/SidebarContent')
  import resizing from '@/mixins/resizing'
  import validate from '@/mixins/validate'
  import { mapGetters } from 'vuex'

  export default {
    name: 'EventNew',
    components: {
      SidebarContent
    },
    mixins: [ resizing, validate ],
    data () {
      const _this = this

      return {
        modalAddInstallment: false,
        modalCalculateParams: false,
        tabActive: 'tabNew',
        isSubcontext: false,
        title: 'Criar Campanha',
        valid: true,
        calculateExternal: false,
        flowActive: false,
        flowsHasValidation: [
          value => {
            const flows = this.model.flows
            return flows.proposal.has || flows.negotiation.has || flows.mediators.has || 'Ao menos um fluxo deve ser selecionado'
          }
        ],
        correctionIndexes: [
          { const: 'selic', title: 'SELIC', disabled: false },
          { const: 'igpm', title: 'IGPM', disabled: false },
          { const: 'inpc', title: 'INPC', disabled: false },
          { const: 'ipca', title: 'IPCA', disabled: false }
        ],
        newRule: {
          parcelOf: '',
          parcelUntil: '',
          calculateParams: {},
          discount: {}
        },
        newCalculateParams: {},
        calculateParams: {
          correctionIndex: '',
          correctionIndexDiscount: 0,
          enableIgnoreTicketsOnInitialProposal: false,
          fees: 0,
          fine: 0,
          fineProRata: false,
          interestRate: 0,
          interestRateFixed: false,
          interestedFullMonth: false,
          passExpenses: false,
          rateInstallments: 0,
          year: ''
        },
        model: {
          general: {
            name: null,
            type: 'credit',
            procedure: 'extra_judicial',
            calculate: 'local',
            enableIgnoreTicketsOnInitialProposal: false,
            passExpenses: false,
          },
          calculateParams: [],
          flows: {
            proposal: {
              has: false,
              expirationDays: 30,
              counterProposal: false,
              installmentRule: []
            },
            negotiation: {
              has: false,
              expirationDays: 30
            },
            mediators: {
              has: false,
              expirationDays: 30,
              volunteer: false,
              professional: false
            }
          },
          channels: {
            send: []
          },
          payment: {
            enabled: null
          },
          other: {
            minParcelValue: '',
            allowanceEmail: '',
            interestedFullMonth: false,
            passExpenses: false,
            enableIgnoreTicketsOnInitialProposal: false
          }
        },
        schema: {
          type: 'object',
          properties: {
            general: {
              title: 'Dados da campanha',
              description: 'A Campanha é a materialização do plano de ação que será executada pela Plataforma Digital. As Campanhas são permanentes (não podem ser excluídas) e ilimitadas, criadas para atender a todos ou a um determinado grupo  de convidados.',
              type: 'object',
              readOnly: false,
              required: [
                'name',
                'type',
                'procedure',
                'calculate'
              ],
              properties: {
                name: {
                  type: 'string',
                  title: 'Nome',
                  'x-props': {
                    placeholder: 'Ex: Concilia 2018, Refis/2019'
                  }
                },
                type: {
                  type: 'string',
                  title: 'Tipo',
                  oneOf: [
                    { const: 'credit', title: 'Crédito', disabled: false },
                    { const: 'pagamento', title: 'Pagamento', disabled: true },
                    { const: 'atendimento', title: 'Atendimento', disabled: true },
                    { const: 'civil', title: 'Cível', disabled: false },
                    { const: 'familiar', title: 'Familiar', disabled: false }
                  ]
                },
                procedure: {
                  type: 'string',
                  title: 'Procedimento',
                  oneOf: [
                    { const: 'extra_judicial', title: 'Extra Judicial', disabled: false },
                    { const: 'judicial', title: 'Judicial', disabled: false }
                  ]
                },
                calculate: {
                  type: 'string',
                  title: 'Calculo',
                  oneOf: [
                    { const: 'local', title: 'Calculo Interno', disabled: false },
                    { const: 'giap', title: 'GIAP', disabled: false }
                  ]
                }
              }
            },
            calculateParams: {
              title: 'Informações para calculo',
              description: 'As Informações para Cálculo servem para a atualizar monetariamente os créditos inadimplidos, bem como aplicar a multa, taxas de juros e demais acréscimos previstos em lei, contrato ou sentença judicial.',
              type: 'object',
              readOnly: false,
              properties: {
                correctionIndex: {
                  type: 'string',
                  title: 'Correção',
                  oneOf: [
                    { const: 'selic', title: 'SELIC', disabled: false },
                    { const: 'igpm', title: 'IGPM', disabled: false },
                    { const: 'inpc', title: 'INPC', disabled: false },
                    { const: 'ipca', title: 'IPCA', disabled: false }
                  ]
                },
                fine: {
                  type: 'string',
                  title: 'Multa Moratória',
                  'x-props': {
                    suffix: '%'
                  }
                },
                interestRate: {
                  type: 'string',
                  title: 'Juros Moratórios',
                  'x-props': {
                    suffix: '%'
                  }
                },
                rateInstallments: {
                  type: 'string',
                  title: 'Juros de Parcelamento',
                  'x-props': {
                    suffix: '%'
                  }
                },
                fees: {
                  type: 'string',
                  title: 'Honorários Advocatícios',
                  'x-props': {
                    suffix: '%'
                  }
                }
              }
            },
            flows: {
              title: 'Fluxos',
              description: 'São as etapas disponibilizadas e que serão percorridas pelos convidados para solucionar as  pendências existentes.',
              type: 'object',
              readOnly: false,
              properties: {
                proposal: {
                  type: 'object',
                  required: [
                    'has',
                    'expirationDays'
                  ],
                  properties: {
                    has: {
                      type: 'boolean',
                      title: 'Proposta Inicial'
                    }
                  },
                  dependencies: {
                    has: {
                      properties: {
                        expirationDays: {
                          type: 'string',
                          title: 'Expiração',
                          'x-props': { suffix: 'dias' }
                        },
                        counterProposal: {
                          type: 'boolean',
                          title: 'Contra-proposta'
                        },
                        installmentRule: {
                          type: 'array',
                          title: 'Regras de parcelamento',
                          'x-itemTitle': 'parcelOf',
                          items: {
                            type: 'object',
                            properties: {
                              parcelOf: {
                                type: 'integer',
                                title: 'Parcela',
                                'x-class': 'xs6'
                              },
                              parcelUntil: {
                                type: 'integer',
                                title: 'Parcela final',
                                'x-class': 'xs6 pl-4'
                              },
                              calculateParams: {
                                title: 'Parâmetro de cálculo nas parcelas selecionadas',
                                type: 'object',
                                readOnly: false,
                                properties: {
                                  correctionIndex: {
                                    type: 'string',
                                    title: 'Correção monetária',
                                    'x-class': 'xs6',
                                    oneOf: [
                                      { const: 'selic', title: 'SELIC', disabled: false },
                                      { const: 'igpm', title: 'IGPM', disabled: false },
                                      { const: 'inpc', title: 'INPC', disabled: false },
                                      { const: 'ipca', title: 'IPCA', disabled: false }
                                    ]
                                  },
                                  correctionIndexDiscount: {
                                    type: 'string',
                                    title: 'Desconto na correção monetária',
                                    'x-class': 'xs6 pl-4',
                                  },
                                  fine: {
                                    type: 'string',
                                    title: 'Multa Moratória',
                                    'x-class': 'xs6',
                                    'x-props': {
                                      suffix: '%'
                                    }
                                  },
                                  interestRate: {
                                    type: 'string',
                                    title: 'Juros Moratórios',
                                    'x-class': 'xs6 pl-4',
                                    'x-props': {
                                      suffix: '%'
                                    }
                                  },
                                  rateInstallments: {
                                    type: 'string',
                                    title: 'Juros de Parcelamento',
                                    'x-class': 'xs6',
                                    'x-props': {
                                      suffix: '%'
                                    }
                                  },
                                  fees: {
                                    type: 'string',
                                    title: 'Honorários Advocatícios',
                                    'x-class': 'xs6 pl-4',
                                    'x-props': {
                                      suffix: '%'
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                },
                negotiation: {
                  type: 'object',
                  required: [
                    'expirationDays'
                  ],
                  properties: {
                    has: {
                      type: 'boolean',
                      title: 'Negociação Online'
                    }
                  },
                  dependencies: {
                    has: {
                      properties: {
                        expirationDays: {
                          type: 'string',
                          title: 'Expiração',
                          'x-props': { suffix: 'dias' }
                        }
                      }
                    }
                  }
                },
                mediators: {
                  type: 'object',
                  required: [
                    'expirationDays'
                  ],
                  properties: {
                    has: {
                      type: 'boolean',
                      title: 'Mediação Online'
                    }
                  },
                  dependencies: {
                    has: {
                      properties: {
                        expirationDays: {
                          type: 'string',
                          title: 'Expiração',
                          'x-props': { suffix: 'dias' }
                        },
                        volunteer: {
                          type: 'boolean',
                          title: 'Mediadores Voluntários',
                          description: 'ssss'
                        },
                        professional: {
                          type: 'boolean',
                          title: 'Mediadores Profissionais'
                        }
                      }
                    }
                  }
                }
              }
            },
            channels: {
              type: 'object',
              readOnly: false,
              title: 'Canais de comunicação',
              description: 'É o meio físico ou virtual, que assegura a circulação das mensagens e informações transmitidas entre a plataforma e todos os envolvidos em caso.',
              properties: {
                send: {
                  type: 'array',
                  title: 'Canais de comunicação',
                  items: {
                    type: 'string',
                    oneOf: [
                      { const: 'email', title: 'E-mail' },
                      { const: 'sms', title: 'SMS' },
                      { const: 'letter', title: 'Carta' }
                    ]
                  }
                }
              }
            },
            payment: {
              type: 'object',
              readOnly: false,
              title: 'Formas de Pagamento',
              description: 'São os meios escolhidos pelo Administrador para receber os pagamentos relativos aos créditos inadimplidos.',
              required: [
                'enabled'
              ],
              properties: {
                enabled: {
                  type: 'string',
                  title: 'Formas de Pagamento',
                  oneOf: [
                    { const: 'pagarme', title: 'Pagar.me' },
                    { const: 'sms', title: 'SMS' },
                    { const: 'letter', title: 'Carta' }
                  ]
                }
              }
            },
            other: {
              title: 'Custos dos serviços contratados',
              description: 'São os valores computadas nos custos  necessários, que foram pagos e exigidos para a atividade.',
              type: 'object',
              readOnly: false,
              properties: {
                minParcelValue: {
                  type: 'number',
                  title: 'Valor minimo da parcela'
                },
                allowanceEmail: {
                  type: 'string',
                  title: 'Email para Solicitação de Dispensa'
                },
                interestedFullMonth: {
                  type: 'boolean',
                  title: 'Calcular juro para o mês inteiro'
                },
                passExpenses: {
                  type: 'boolean',
                  title: 'Incluir valor as despesas na dívida'
                },
                enableIgnoreTicketsOnInitialProposal: {
                  type: 'boolean',
                  title: 'Configurar títulos na proposta inicial'
                }
              }
            }
          }
        },
        formOptions: {
          fieldProps: {
            filled: true
          },
          selectProps: {
            clearable: true
          },
          messages: {
            required: 'Campo obrigatório',
            minItems: 'Campo obrigatório'
          },
          sectionsClass: 'formSection',
          childrenClass: 'pa-0',
          hideReadOnly: true,
          removeAdditionalProperties: false
        },
        openModal: false,
        alwaysOpened: true,
        eventId: null,
        displayModeParams: false,
        ticketChecked: false,
        creditCardChecked: false,
        pagarmeChecked: false,
        generateFileChecked: false,
        newEvent: {
          general: {
            type: 'credit',
            procedure: 'extra_judicial',
            name: '',
            calculate: 'local'
          },
          flows: {
            proposal: {
              has: false,
              expirationDays: 90, // atualizado com o global, podendo ser editado
              counterProposal: false,
              installmentRule: {
                parcelOf: 1,
                parcelUntil: 1,
                calculateParams: {
                  correctionIndex: '',
                  correctionIndexDiscount: 0,
                  fine: 0,
                  interestRate: 0,
                  rateInstallments: 0,
                  fees: 0,
                  passExpenses: false,
                  interestedFullMonth: false,
                  minParcelValue: 0
                },
                discount: {
                  type: 'originalValue',
                  value: 0
                }
              }
            },
            negotiation: {
              has: false,
              expirationDays: 60 // atualizado com o global, podendo ser editado
            },
            directNegotiation: {
              has: false
            },
            mediators: {
              has: false,
              expirationDays: 60 // atualizado com o global, podendo ser editado
            }
          }
        },
        payment: {
          ticket: false,
          creditCard: false,
          generateFile: false,
          reports: false,
          pagarme: false,
        },
        paymentRules: [
          v => {
            const keys = Object.keys(_this.payment)
            const hasPayment = keys.filter(key => _this.payment[key])
            return hasPayment.length > 0 || ''
          }
        ]
      }
    },
    computed: {
      ...mapGetters({
        currentCompanyPayment: 'currentCompany/payment',
        channels: 'currentCompany/channels',
        calculateParams: 'currentCompany/calculateParams',
        plan: 'currentCompany/plan'
      }),
      isLoading () {
        return this.$store.state.global.isLoading
      },
      installmentRuleTitle () {
        return this.newRule.parcelUntil === 1 ? 'Regra à vista' : 'Regras de parcelamento'
      }
    },
    watch: {
      'model.general.calculate' (value) {
        this.schema.properties.payment.readOnly = value !== 'local'
        this.schema.properties.other.readOnly = value !== 'local'

        if(value !== 'local') {
          this.model.other = {
            minParcelValue: '',
            allowanceEmail: '',
            interestedFullMonth: false,
            passExpenses: false,
            enableIgnoreTicketsOnInitialProposal: false
          }
        }
      },
      'model.general.type' (value) {
        const disabled = ['civil', 'familiar'].includes(value)

        this.schema.properties.calculateParams.readOnly = disabled
        this.schema.properties.flows.properties.proposal.readOnly = disabled
        this.schema.properties.flows.properties.negotiation.readOnly = disabled
      },
      'model.general.calculate' (value) {
        const disabled = ['giap'].includes(value)
        this.calculateExternal = disabled
        this.schema.properties.calculateParams.readOnly = disabled
        this.schema.properties.payment.readOnly = disabled
      },
      'model.flows.proposal.has' (value) {
        if(value === true) {
          this.editFlowProposal()
        }
      },
      'model.flows.negotiation.has' (value) {
        if(value === true) {
          this.editFlowNegotiation()
        }
      },
      'model.flows.mediators.has' (value) {
        if(value === true) {
          this.editFlowMediation()
        }
      }
    },
    mounted () {
      this.showModal()

      const params = ['correctionIndex', 'fine', 'interestRate', 'rateInstallments', 'fees']
      // this.calculateParams.passExpenses = this.plan.passExpenses
      this.model.other.passExpenses = this.plan.passExpenses

      const channelsEnabled = []
      for(const key in this.channels) {
        if(this.channels[key].send) {
          channelsEnabled.push(key)
        }
      }

      this.model.channels.send = channelsEnabled

      for (const key in this.calculateParams) {
        if(params.includes(key)) {
          this.model.calculateParams[key] = this.calculateParams[key]
        }
      }

      this.schema.properties.payment.properties.enabled.oneOf = this.currentCompanyPayment.map(item => ({ const: item.kind, title: item.title }))
    },
    methods: {
      sideContentBack () {
        if (this.$refs.form.validate()) {
          const flows = this.schema.properties.flows

          this.flowActive = false
          this.isSubcontext = false
          this.tabActive = 'tabNew'
          this.title = 'Criar Campanha'
          this.hideItems(false)

          flows.properties.proposal.dependencies.has.properties.expirationDays.readOnly = true
          flows.properties.proposal.dependencies.has.properties.counterProposal.readOnly = true
          flows.properties.proposal.dependencies.has.properties.installmentRule.readOnly = true

          flows.properties.negotiation.dependencies.has.properties.expirationDays.readOnly = true

          flows.properties.mediators.dependencies.has.properties.expirationDays.readOnly = true
          flows.properties.mediators.dependencies.has.properties.volunteer.readOnly = true
          flows.properties.mediators.dependencies.has.properties.professional.readOnly = true
        }
      },
      showModal () {
        this.openModal = true
      },
      closeModal () {
        this.openModal = false
        this.$nextTick(() => {
          let returnTo = this.$route.query.returnTo ? this.$route.query.returnTo : 'Events'
          this.$router.push({ name: returnTo })
        })
      },
      crateCampaign (data) {
        if(this.$refs.form.validate()) {
          data = data.clone()

          const payment = this.payment.clone()

          const channels = ['email', 'sms', 'letter'].reduce((acc, key) => {
            acc[key] = {
              send: data.channels.send.includes(key)
            }

            return acc
          }, {})

          let params = {
            ...data,
            ...data.general,
            ...data.flows,
            ...channels,
            calculateParams: {
              ...data.calculateParams,
              enableIgnoreTicketsOnInitialProposal: data.other.enableIgnoreTicketsOnInitialProposal,
              interestedFullMonth: data.other.interestedFullMonth,
              passExpenses: data.other.passExpenses
            },
            minParcelValue: data.other.minParcelValue,
            allowanceEmail: data.other.allowanceEmail,
            payment
          }

          delete params.general
          delete params.flows
          delete params.other
          delete params.channels

          params = this.processData(params)

          const id = this.$uuid.v4()

          this.$store.dispatch('events/create', { ...params, id: id })
            .then(this._success)
            .catch(this._failure)
        }

      },
      processData(obj) {
        for (const key in obj) {
          if(obj[key] === undefined) {
            obj[key] = ''
          } else if(Array.isArray(obj[key])) {
            const arr = []
            for (const item of obj[key]) {
              arr.push(this.processData(item))
            }

            obj[key] = arr
          } else if (typeof obj[key] === 'object') {
            obj[key] = this.processData(obj[key])
          }
        }

        return obj
      },
      _success () {
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, color: 'success', text: 'Campanha criada com sucesso'})
        this.closeModal()
      },
      _failure () {
        console.log(...arguments)
        this.$store.commit('app/setLoading', false)
        this.$root.$emit('snackbar', {show: true, time: 10000, color: 'error', text: 'houve um problema'})
      },
      modalRulesOpened (status) {
        this.alwaysOpened = status
      },
      setProposalObject () {
        this.newEvent.flows.proposal.installmentRule.calculateParams.correctionIndex = this.calculateParams.correctionIndex
        this.newEvent.flows.proposal.installmentRule.calculateParams.passExpenses = this.calculateParams.passExpenses
      },
      hideItems (value) {
        this.schema.properties.general.readOnly = value
        this.schema.properties.calculateParams.readOnly = value
        this.schema.properties.channels.readOnly = value
        this.schema.properties.payment.readOnly = value
        this.schema.properties.other.readOnly = value
        this.schema.properties.flows.properties.proposal.properties.has.readOnly = value
        this.schema.properties.flows.properties.negotiation.properties.has.readOnly = value
        this.schema.properties.flows.properties.mediators.properties.has.readOnly = value
      },
      editFlow (title) {
        this.hideItems(true)
        this.isSubcontext = true
        this.title = title
        this.flowActive = true
      },
      editFlowProposal () {
        this.editFlow('Proposta Inicial')
        this.model.flows.proposal.has = true
        this.schema.properties.flows.properties.proposal.dependencies.has.properties.expirationDays.readOnly = false
        this.schema.properties.flows.properties.proposal.dependencies.has.properties.counterProposal.readOnly = this.calculateExternal || false
        this.schema.properties.flows.properties.proposal.dependencies.has.properties.installmentRule.readOnly = this.calculateExternal || false
      },
      editFlowNegotiation () {
        this.editFlow('Negociação Online')
        this.model.flows.negotiation.has = true
        this.schema.properties.calculateParams.readOnly = true
        this.schema.properties.flows.properties.negotiation.dependencies.has.properties.expirationDays.readOnly = false
      },
      editFlowMediation () {
        this.editFlow('Mediação Online')
        this.model.flows.mediators.has = true
        this.schema.properties.calculateParams.readOnly = true
        this.schema.properties.flows.properties.mediators.dependencies.has.properties.expirationDays.readOnly = false
        this.schema.properties.flows.properties.mediators.dependencies.has.properties.volunteer.readOnly = false
        this.schema.properties.flows.properties.mediators.dependencies.has.properties.professional.readOnly = false
      },
      addCalculateParams () {
        this.modalCalculateParams = true

        this.newCalculateParams = this.calculateParams.clone()
      },
      editCalculateParams (rule) {
        this.modalCalculateParams = true

        this.newCalculateParams = rule
      },
      saveCalculateParams (data) {
        console.log(data)
        this.closeCalculateParams()
      },
      saveCalculateParams2 (data) {
        const installmentRule = this.model.flows.proposal.installmentRule

        let oldData = installmentRule.find(item => item.parcelOf === data.parcelOf && item.parcelUntil === data.parcelUntil)

        oldData ? oldData = data : installmentRule.push(data)

        this.closeCalculateParams()
      },
      closeCalculateParams () {
        this.modalCalculateParams = false

        this.$nextTick(() => {
          this.newCalculateParams = this.calculateParams.clone()
        })
      },
      addInstallmentRule () {
        this.modalAddInstallment = true

        const installmentRule = this.model.flows.proposal.installmentRule.clone()
        const parcelUntil = (installmentRule.length > 0 ? installmentRule.pop().parcelUntil : 0) + 1

        const rule = {
          calculateParams: {
            ...this.model.calculateParams,
            correctionIndexDiscount: ''
          },
          discount: {
            type: 'originalValue',
            value: ''
          },
          parcelOf: parcelUntil,
          parcelUntil: parcelUntil
        }

        this.newRule = rule
      },
      editInstallmentRule (rule) {
        this.modalAddInstallment = true

        this.newRule = rule
      },
      saveInstallmentRule (data) {
        const installmentRule = this.model.flows.proposal.installmentRule

        let oldData = installmentRule.find(item => item.parcelOf === data.parcelOf && item.parcelUntil === data.parcelUntil)

        oldData ? oldData = data : installmentRule.push(data)

        this.closeInstallmentRule()
      },
      closeInstallmentRule (data) {
        this.modalAddInstallment = false

        this.$nextTick(() => {
          this.newRule = {
            parcelOf: '',
            parcelUntil: '',
            calculateParams: {},
            discount: {}
          }
        })
      },
      calculateCorrectionIndex ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.calculateParams.correctionIndexDiscount = percent
      },
      calculateFine ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.calculateParams.fine = percent
      },
      calculateInterestRate ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.calculateParams.interestRate = percent
      },
      calculateRateInstallments ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.calculateParams.rateInstallments = percent
      },
      calculateFees ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.calculateParams.fees = percent
      },
      calculateDiscount ({ value, isCurrency }) {
        const multi = isCurrency ? 1000 : 100
        const percent = parseFloat((value * multi).toFixed(2))
        this.newRule.discount.value = percent
      }
    }
  }
</script>

<style lang="sass">
  .negative-mt-30
    margin-top: -30px !important

  .event-stepper
    .v-stepper__label
      display: flex
      flex-direction: row
      align-items: center

      .info-icon
        padding-top: 5px
        text-shadow: none

  .modalCentral
    border-radius: 20px
    z-index: 204
</style>
